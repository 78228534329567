import React from "react";
import Section from "../Section/Section";
import marked from "marked";
import classes from "./ContentSection.module.css";

export default function ContentSection({ content }) {
  return (
    <Section className={classes.contentSection}>
      <div dangerouslySetInnerHTML={{ __html: marked(content.content) }} />
    </Section>
  );
}
