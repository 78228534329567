import React from "react";
import Section from "../Section/Section";
import CallToActions from "../../components/CallToActions/CallToActions";
import classNames from "classnames";
import marked from "marked";
import classes from "./HeroSection.module.css";

function HeroSection({
  title,
  subtitle,
  callToActions,
  phoneScreenImage,
  centered,
  className,
  innerClassName,
}) {
  return (
    <Section
      className={classNames(
        classes.hero,
        { [classes.centered]: centered },
        className
      )}
      innerClassName={classNames(classes.inner, innerClassName)}
    >
      <div className={classes.wrapper}>
        <div className={classes.section}>
          <h1>{title}</h1>
          {subtitle && (
            <div dangerouslySetInnerHTML={{ __html: marked(subtitle) }} />
          )}
          {callToActions && <CallToActions callToActions={callToActions} />}
        </div>
        {phoneScreenImage && (
          <div className={classes.section}>
            <div className={classes.phoneWrapper}>
              <video autoPlay muted loop playsInline>
                <source src={phoneScreenImage} type="video/mp4" />
              </video>

              <div className={classes.phone} />
            </div>
          </div>
        )}
      </div>
    </Section>
  );
}

export default HeroSection;
