import React from "react";
import Section from "../Section/Section";
import classes from "./TestimonialSection.module.css";
import Testimonial from "./Testimonial";

function TestimonialSection({ author, testimonial, image }) {
  return (
    <Section className={classes.testimonial} innerClassName={classes.inner}>
      <Testimonial testimonial={testimonial} author={author} />
      <img src={image} alt="" />
    </Section>
  );
}

export default TestimonialSection;
