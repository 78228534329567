import React, { useState } from "react";
import { isEmail } from "validator";
import { load } from "recaptcha-v3";
import classNames from "classnames";
import classes from "./RequestDemoCallToAction.module.css";

export default function RequestDemoCallToAction({
  buttonLabel,
  placeholder,
  secondary,
  section,
  intercomTags,
}) {
  const [email, setEmail] = useState("");
  const valid = email !== "" && isEmail(email);
  const [submitted, setSubmitted] = useState(false);

  async function requestDemo(e) {
    e.preventDefault();
    if (submitted) return;

    const recaptcha = await load("6LdCxt4UAAAAAH3ZIW1eEtuyo09Um0kR8L6aG3dH");
    const recaptchaToken = await recaptcha.execute("request_demo");

    const normalizedIntercomTags = (intercomTags || "")
      .split(",")
      .map(t => t.trim());

    await fetch("http://localhost:8080/demo", {
      method: "POST",
      body: JSON.stringify({
        email,
        page: window.location.pathname,
        section,
        tags: normalizedIntercomTags,
        "g-recaptcha-response": recaptchaToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    setSubmitted(true);
  }

  return (
    <form className={classes.requestDemo} onSubmit={requestDemo}>
      <input
        type="email"
        placeholder={placeholder}
        disabled={submitted}
        value={email}
        onChange={e => setEmail(e.target.value)}
        required
      />
      <button
        type="submit"
        className={classNames({ [classes.secondaryButton]: secondary })}
        disabled={!valid || submitted}
      >
        {submitted ? "We'll be in touch!" : buttonLabel}
      </button>
    </form>
  );
}
