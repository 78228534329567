import React, { useState } from "react";
import classes from "./Nav.module.css";
import {
  Button,
  Popover,
  Menu,
  MenuItem,
  Icon,
  MenuDivider,
  Position,
  Dialog,
  Classes,
} from "@blueprintjs/core";
import { navigate } from "gatsby";
import RequestDemoForm from "../RequestDemoForm/RequestDemoForm";

function NavLinks({ onClick }) {
  const [requestDemoOpen, setRequestDemoOpen] = useState(false);

  function linkTo(page) {
    navigate(page);
    onClick();
  }

  return (
    <>
      <Dialog
        isOpen={requestDemoOpen}
        title=""
        onClose={() => setRequestDemoOpen(false)}
      >
        <div className={Classes.DIALOG_BODY}>
          <RequestDemoForm minimal />
        </div>
      </Dialog>

      <ul className={classes.links}>
        <li>
          <Popover>
            <Button
              minimal
              large
              text="Product"
              rightIcon={<Icon icon="chevron-down" color="#FFF" />}
              className={classes.buttonLink}
            />
            <Menu large>
              <MenuItem text="Features" onClick={() => linkTo("/features")} />
              <MenuItem text="Why" onClick={() => linkTo("/why")} />
              <MenuItem text="Campaigns" onClick={() => linkTo("/campaigns")} />
              <MenuItem
                text="Video Affiliate"
                onClick={() => linkTo("/video-affiliate")}
              />
              <MenuItem
                text="Attribution"
                onClick={() => linkTo("/attribution")}
              />
              <MenuItem
                text="Influencer ROI"
                onClick={() => linkTo("/influencer-roi")}
              />
            </Menu>
          </Popover>
        </li>

        <li>
          <Popover position={Position.BOTTOM}>
            <Button
              minimal
              large
              text="Solutions"
              rightIcon={<Icon icon="chevron-down" color="#FFF" />}
              className={classes.buttonLink}
            />
            <Menu large>
              <MenuDivider title="Industries" />

              <MenuItem text="Beauty" onClick={() => linkTo("/beauty")} />
              <MenuItem text="Fashion" onClick={() => linkTo("/fashion")} />
              <MenuItem text="Food" onClick={() => linkTo("/food")} />

              <MenuDivider title="Use Cases" />

              <MenuItem text="Marketers" onClick={() => linkTo("/marketers")} />
              <MenuItem
                text="Product Owners"
                onClick={() => linkTo("/product-owners")}
              />
              <MenuItem text="Agencies" onClick={() => linkTo("/agencies")} />
              <MenuItem
                text="Content Creators"
                onClick={() => linkTo("/content-creators")}
              />
              <MenuItem
                text="Influencers"
                onClick={() => linkTo("/influencers")}
              />
              <MenuItem
                text="Publishers"
                onClick={() => linkTo("/publishers")}
              />
              <MenuItem
                text="Political Campaigns"
                onClick={() => linkTo("/politics")}
              />
              <MenuItem
                text="Non-Profits"
                onClick={() => linkTo("/nonprofits")}
              />
            </Menu>
          </Popover>
        </li>

        <li>
          <Button
            minimal
            large
            text="Insights"
            className={classes.buttonLink}
            onClick={() => window.open("https://intercom.help/videosquirrel")}
          />
        </li>
      </ul>

      <ul className={classes.buttons}>
        <li>
          <a href="https://brands.videosquirrel.app">Log In</a>
        </li>

        <li>
          <a onClick={() => setRequestDemoOpen(true)}>Get a Demo</a>
        </li>
      </ul>
    </>
  );
}

export default NavLinks;
