import { useEffect } from "react";

export default function useScrollHeight() {
  // The debounce function receives our function as a parameter
  const debounce = fn => {
    // This holds the requestAnimationFrame reference, so we can cancel it if we wish
    let frame;

    // The debounce function returns a new function that can receive a variable number of arguments
    return (...params) => {
      // If the frame variable has been defined, clear it now, and queue for next frame
      if (frame) {
        cancelAnimationFrame(frame);
      }

      // Queue our function call for the next frame
      frame = requestAnimationFrame(() => {
        // Call our function and pass any params we received
        fn(...params);
      });
    };
  };

  // Reads out the scroll position and stores it in the data attribute
  // so we can use it in our stylesheets
  const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY;
    document.documentElement.dataset.scrollGt80 = window.scrollY > 80;
  };

  useEffect(() => {
    const scrollHandler = debounce(storeScroll);

    // Listen for new scroll events, here we debounce our `storeScroll` function
    document.addEventListener("scroll", scrollHandler, { passive: true });

    // Update scroll position for first time
    storeScroll();

    return () => document.removeEventListener("scroll", scrollHandler);
  }, []);
}
