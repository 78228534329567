import React from "react";
import classes from "./Legal.module.css";

function Legal() {
  return (
    <div className={classes.legal}>
      <p>
        &copy; 2017-2019 All Rights Reserved. Video Squirrel™ is a registered
        trademark of Video Squirrel Inc. Apple and the Apple logo are trademarks
        of Apple Inc. registered in the U.S. and other countries. Mac Apple
        Store is a service mark of Apple Inc. Google Play and the Google Play
        logo are trademarks of Google Inc.
      </p>
    </div>
  );
}

export default Legal;
