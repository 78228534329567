import React from "react";
import Section from "../Section/Section";
import classes from "./AudienceSection.module.css";
import { Link } from "gatsby";

function AudienceSection({ title, userGroups }) {
  const mapUserGroup = (userGroup, index) => (
    <Link
      className={classes.userGroup}
      key={`${userGroup.name}-${index}`}
      to={userGroup.link}
    >
      <img src={userGroup.icon.file.url} alt={userGroup.name} />
      <p>{userGroup.name}</p>
    </Link>
  );

  return (
    <Section className={classes.audience} innerClassName={classes.inner}>
      <h1>{title}</h1>
      <div className={classes.userGroups}>{userGroups.map(mapUserGroup)}</div>
    </Section>
  );
}

export default AudienceSection;
