import React from "react";
import Section from "../Section/Section";
import classes from "./MediumPostSection.module.css";
import classNames from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import HeroSection from "../HeroSection/HeroSection";

function MediumPostSection({ showAreYouReadyAllReady }) {
  const { allMediumPost } = useStaticQuery(graphql`
    {
      allMediumPost(sort: { fields: createdAt, order: DESC }, limit: 3) {
        edges {
          node {
            title
            medium_id
            virtuals {
              subtitle
              previewImage {
                imageId
              }
            }
          }
        }
      }
    }
  `);

  const posts = allMediumPost.edges.map(edge => ({
    id: edge.node.medium_id,
    title: edge.node.title,
    subtitle: edge.node.virtuals.subtitle,
    previewImage: `https://cdn-images-1.medium.com/max/620/${edge.node.virtuals.previewImage.imageId}`,
    url: `https://medium.com/videosquirrel/${edge.node.medium_id}`,
  }));

  const mapMediumPost = post => (
    <div key={post.id} className={classes.mediumPostItem}>
      <a target="_blank" rel="noopener noreferrer" href={post.url}>
        <img src={post.previewImage} alt={post.title} />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={post.url}>
        <h3>{post.title}</h3>
      </a>
      <p>{post.subtitle}</p>
    </div>
  );

  return (
    <>
      {showAreYouReadyAllReady && (
        <HeroSection
          title="Are you ready already?"
          subtitle="It's easy to start, and when you join our beta program it's even easier."
          callToAction={{
            label: "Join Us",
            link: "https://app.zipline.co/dashboard/create-account",
          }}
          innerClassName={classes.heroInner}
          centered
        />
      )}
      <Section
        className={classNames(classes.mediumPost, {
          [classes.withHero]: showAreYouReadyAllReady === true,
        })}
        innerClassName={classes.inner}
      >
        {posts.map(mapMediumPost)}
      </Section>
    </>
  );
}

export default MediumPostSection;
