import React from "react";
import Section from "../../sections/Section/Section";
import FooterLinks from "./FooterLinks";
import Legal from "./Legal";
import classes from "./Footer.module.css";

function Footer() {
  return (
    <Section className={classes.footer} innerClassName={classes.inner}>
      <FooterLinks />
      <Legal />
    </Section>
  );
}

export default Footer;
