import React from "react";
import Section from "../Section/Section";
import classes from "./BrandPartnershipSection.module.css";

function BrandPartnershipSection({ title, brands }) {
  const mapBrand = (brand, index) => (
    <img
      key={`${brand.brandName}-${index}`}
      src={brand.logo.file.url}
      alt={brand.brandName}
    />
  );

  return (
    <Section
      className={classes.brandPartnership}
      innerClassName={classes.inner}
    >
      <h1>{title}</h1>
      <div className={classes.logos}>{brands.map(mapBrand)}</div>
    </Section>
  );
}

export default BrandPartnershipSection;
