import React from "react";
import classes from "./FeatureSection.module.css";
import Section from "../Section/Section";
import ButtonCallToAction from "../../components/ButtonCallToAction/ButtonCallToAction";

function FeatureSection({ title, features }) {
  const mapFeature = feature => (
    <div key={feature.title} className={classes.featureItem}>
      <img src={feature.icon.file.url} alt="" />
      <h3>{feature.title}</h3>
      <p>{feature.description.description}</p>
      {feature.callToAction && (
        <ButtonCallToAction
          link={feature.callToAction.link}
          label={feature.callToAction.label}
          secondary
        />
      )}
    </div>
  );

  return (
    <Section className={classes.feature} innerClassName={classes.inner}>
      {title && <h1>{title}</h1>}
      <div className={classes.featureList}>{features.map(mapFeature)}</div>
    </Section>
  );
}

export default FeatureSection;
