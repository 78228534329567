import React, { useState } from "react";
import classes from "./Nav.module.css";
import classNames from "classnames";
import { Link } from "gatsby";
import NavLinks from "./NavLinks";

function Nav() {
  const [navigationOpen, setNavigationOpen] = useState(false);

  return (
    <nav className={classes.nav}>
      <div className={classes.inner}>
        <Link
          to="/"
          className={classes.logo}
          onClick={() => setNavigationOpen(false)}
        >
          <img style={{ height: 45 }} src={require("./logo_mark.svg")} alt="" />
          <img style={{ height: 25 }} src={require("./logo_text.svg")} alt="" />
        </Link>

        <div
          className={classNames(classes.navigation, {
            [classes.open]: navigationOpen,
          })}
        >
          <NavLinks onClick={() => setNavigationOpen(false)} />
        </div>

        {/*eslint-disable-next-line*/}
        <a
          className={classes.menuButton}
          onClick={() => setNavigationOpen(!navigationOpen)}
        >
          <img
            src={navigationOpen ? require("./x.svg") : require("./menu.svg")}
            alt=""
          />
        </a>
      </div>
    </nav>
  );
}

export default Nav;
