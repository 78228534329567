import React from "react";
import classNames from "classnames";
import classes from "./Section.module.css";

function Section({
  className,
  innerClassName,
  children,
  darkened,
  hasColumns,
  reversed,
}) {
  return (
    <div
      className={classNames(classes.section, className, {
        [classes.columns]: hasColumns,
        [classes.reversed]: reversed,
        [classes.darkened]: darkened,
      })}
    >
      <div className={classNames(classes.inner, innerClassName)}>
        {children}
      </div>
    </div>
  );
}

export default Section;
