import React from "react";
import marked from "marked";
import classes from "./SecondaryHeroSection.module.css";

function SecondaryHeroSection({ content, children }) {
  return (
    <div className={classes.secondaryHero}>
      <div className={classes.wrapper}>
        {content && (
          <div dangerouslySetInnerHTML={{ __html: marked(content) }} />
        )}
        {children && children}
      </div>
    </div>
  );
}

export default SecondaryHeroSection;
