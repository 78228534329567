import React from "react";
import classes from "./FooterLinks.module.css";
import { Link } from "gatsby";

function FooterLinks() {
  return (
    <div className={classes.footerLinks}>
      <div>
        <Link to="/">
          <img
            style={{ height: 45 }}
            src={require("./logo_lockup.svg")}
            alt=""
          />
        </Link>
      </div>

      <div className={classes.links}>
        <div className={classes.linkGroup}>
          <h4>Follow Us</h4>
          <div className={classes.socialLinks}>
            <a
              href="http://fb.me/videosquirrel"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("./images/facebook.svg")} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com/videosquirrel/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("./images/instagram.svg")} alt="Instagram" />
            </a>
            <a
              href="https://twitter.com/VideoSquirrel"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("./images/twitter.svg")} alt="Twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/videosquirrel"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require("./images/linkedin.svg")} alt="LinkedIn" />
            </a>
          </div>
        </div>

        <div className={classes.linkGroup}>
          <h4>About Us</h4>
          <ul>
            <li>
              <Link to="/company">Our Company</Link>
            </li>
            <li>
              <a
                href="https://angel.co/company/zipline-live/jobs"
                target="_blank"
                rel="noopener noreferrer"
              >
                Careers
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/videosquirrel"
                target="_blank"
                rel="noopener noreferrer"
              >
                News
              </a>
            </li>
          </ul>
        </div>

        <div className={classes.linkGroup}>
          <h4>Legal</h4>
          <ul>
            <li>
              <Link to="/tos">Terms &amp; Conditions</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
          </ul>
        </div>

        <div className={classes.linkGroup}>
          <h4>Help</h4>
          <ul>
            <li>
              <a
                href="https://intercom.help/videosquirrel"
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQ
              </a>
            </li>
            {typeof window !== "undefined" && window.Intercom && (
              <li>
                <a
                  onClick={() =>
                    typeof window !== "undefined" && window.Intercom("show")
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FooterLinks;
