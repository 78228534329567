import React, { useState } from "react";
import { FormGroup, InputGroup } from "@blueprintjs/core";
import { load } from "recaptcha-v3";
import { isEmail } from "validator";
import classNames from "classnames";
import classes from "./RequestDemoForm.module.css";

export default function RequestDemoForm({ minimal }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const valid = !!email && isEmail(email);

  async function requestDemo(e) {
    e.preventDefault();
    if (submitted) return;

    const recaptcha = await load("6LdCxt4UAAAAAH3ZIW1eEtuyo09Um0kR8L6aG3dH");
    const recaptchaToken = await recaptcha.execute("request_demo");

    await fetch("https://api.zipline.co/demo", {
      method: "POST",
      body: JSON.stringify({
        name,
        email,
        phone,
        company,
        page: window.location.pathname,
        "g-recaptcha-response": recaptchaToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    setSubmitted(true);
  }

  return (
    <div
      className={classNames(classes.requestDemo, {
        [classes.minimal]: minimal,
      })}
    >
      <h1>Let's Schedule a Demo</h1>

      <form onSubmit={requestDemo}>
        <FormGroup label="Name">
          <InputGroup
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            disabled={submitted}
            large
          />
        </FormGroup>

        <FormGroup label="Contact Email">
          <InputGroup
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            disabled={submitted}
            large
          />
        </FormGroup>

        <FormGroup label="Phone">
          <InputGroup
            type="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            disabled={submitted}
            required
            large
          />
        </FormGroup>

        <FormGroup label="Organization">
          <InputGroup
            type="text"
            value={company}
            onChange={e => setCompany(e.target.value)}
            disabled={submitted}
            large
          />
        </FormGroup>

        <button disabled={!valid || submitted} className={classes.button}>
          {submitted ? "We'll be in touch!" : "Get Demo"}
        </button>
      </form>
    </div>
  );
}
