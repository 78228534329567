import React from "react";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import useScrollHeight from "../../hooks/useScrollHeight";
import SEO from "../SEO/SEO";

function Page({ title, description, image, children }) {
  useScrollHeight();

  return (
    <>
      <SEO title={title} description={description} image={image} />
      <Nav />
      {children}
      <Footer />
    </>
  );
}

export default Page;
