import React from "react";
import Section from "../Section/Section";
import SectionColumn from "../Section/SectionColumn";
import classNames from "classnames";
import classes from "./ColumnSection.module.css";

export default function ColumnLayout({
  children,
  darkened,
  reversed,
  className,
  verticallyCentered,
}) {
  return (
    <Section
      className={classNames(classes.columnSection, className, {
        [classes.verticallyCentered]: verticallyCentered,
      })}
      darkened={darkened}
      reversed={reversed}
      hasColumns
    >
      <SectionColumn>{children[0]}</SectionColumn>
      <SectionColumn>{children[1]}</SectionColumn>
    </Section>
  );
}
