import React from "react";
import classes from "./TestimonialSection.module.css";

export default function Testimonial({ testimonial, author, reference }) {
  return (
    <div className={classes.quote}>
      <blockquote>{testimonial}&quot;</blockquote>
      <p>&mdash; {author}</p>
      {reference && (
        <small>
          <a target="_blank" rel="noopener noreferrer" href={reference}>
            {reference}
          </a>
        </small>
      )}
    </div>
  );
}
