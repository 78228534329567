import React from "react";
import CallToActions from "../../components/CallToActions/CallToActions";
import marked from "marked";
import classes from "./ColumnSection.module.css";
import RequestDemoForm from "../../components/RequestDemoForm/RequestDemoForm";
import ColumnLayout from "./ColumnLayout";

function ColumnSection({
  title,
  content,
  image,
  phoneScreenImage,
  callToActions,
  showRequestDemoForm,
  darkened,
  imageSide,
  videoUrl,
  verticallyCentered,
}) {
  return (
    <ColumnLayout
      reversed={!imageSide}
      darkened={darkened}
      verticallyCentered={verticallyCentered}
    >
      <div>
        {image && <img src={image} alt="" />}

        {phoneScreenImage && (
          <div className={classes.phone}>
            <div className={classes.screen}>
              <img src={phoneScreenImage} alt="" />
            </div>
          </div>
        )}

        {showRequestDemoForm && <RequestDemoForm />}

        {videoUrl && (
          <iframe
            width="500"
            height="281"
            src={videoUrl}
            frameborder="0"
            gesture="media"
            allow="encrypted-media"
            allowfullscreen
          ></iframe>
        )}
      </div>

      <div>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: marked(content) }} />
        {callToActions && (
          <CallToActions callToActions={callToActions} secondary />
        )}
      </div>
    </ColumnLayout>
  );
}

export default ColumnSection;
