import React from "react";
import Page from "../../components/Page/Page";
import HeroSection from "../../sections/HeroSection/HeroSection";
import FeatureSection from "../../sections/FeatureSection/FeatureSection";
import TestimonialSection from "../../sections/TestimonialSection/TestimonialSection";
import MediumPostSection from "../../sections/MediumPostSection/MediumPostSection";
import BrandPartnershipSection from "../../sections/BrandPartnershipSection/BrandPartnershipSection";
import { graphql } from "gatsby";
import ColumnSection from "../../sections/ColumnSection/ColumnSection";
import SidekickSection from "../../sections/SidekickSection/SidekickSection";
import AudienceSection from "../../sections/AudienceSection/AudienceSection";
import ContentSection from "../../sections/ContentSection/ContentSection";
import SecondaryHeroSection from "../../sections/SecondaryHeroSection/SecondaryHeroSection";

function LandingPage({ title, data, location }) {
  const pageNode = data.allContentfulLandingPage.edges[0].node;
  const {
    heroTitle,
    heroSubtitle,
    heroCallToActions,
    phoneScreenImage,
    centered,
    seoTitle,
    seoDescription,
    seoImage,
  } = pageNode;

  const mapContentSectionSection = (contentSection, index) => {
    switch (contentSection.__typename) {
      case "ContentfulContentSection":
        return (
          <ContentSection
            key={`${contentSection.__typename}-${index}`}
            content={contentSection.content}
          />
        );

      case "ContentfulFeatureSection":
        return (
          <FeatureSection
            key={`${contentSection.__typename}-${index}`}
            title={contentSection.title}
            features={contentSection.features}
          />
        );

      case "ContentfulTestimonialSection":
        return (
          <TestimonialSection
            key={`${contentSection.__typename}-${index}`}
            author={contentSection.author.author}
            testimonial={contentSection.testimonial.content[0].content[0].value}
            image={contentSection.image.file.url}
          />
        );

      case "ContentfulMediumSection":
        return (
          <MediumPostSection
            key={`${contentSection.__typename}-${index}`}
            showAreYouReadyAllReady={contentSection.showAreYouReadyAllReady}
          />
        );

      case "ContentfulSecondaryHeroSection":
        return (
          <SecondaryHeroSection
            key={`${contentSection.__typename}-${index}`}
            content={contentSection.content.content}
          />
        );

      case "ContentfulSidekickSection":
        return (
          <SidekickSection
            key={`${contentSection.__typename}-${index}`}
            title={contentSection.title}
            subtitle={
              contentSection.subtitle && contentSection.subtitle.subtitle
            }
          />
        );

      case "ContentfulLogoSection":
        return (
          <BrandPartnershipSection
            key={`${contentSection.__typename}-${index}`}
            title={contentSection.title}
            brands={contentSection.brands}
          />
        );

      case "ContentfulColumnSection":
        if (!contentSection.content) {
          console.log(contentSection);
        }
        return (
          <ColumnSection
            key={`${contentSection.__typename}-${index}`}
            title={contentSection.title}
            content={contentSection.content.content}
            image={contentSection.image && contentSection.image.file.url}
            phoneScreenImage={
              contentSection.phoneScreenImage &&
              contentSection.phoneScreenImage.file.url
            }
            callToActions={contentSection.callToActions}
            darkened={contentSection.darkened}
            imageSide={contentSection.imageSide}
            showRequestDemoForm={contentSection.showRequestDemoForm}
          />
        );

      case "ContentfulAudienceSection":
        return (
          <AudienceSection
            key={`${contentSection.__typename}-${index}`}
            title={contentSection.title}
            userGroups={contentSection.userGroups}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Page
      title={seoTitle}
      description={seoDescription && seoDescription.seoDescription}
      image={seoImage && seoImage.file.url}
    >
      <HeroSection
        title={heroTitle}
        subtitle={heroSubtitle && heroSubtitle.heroSubtitle}
        phoneScreenImage={phoneScreenImage && phoneScreenImage.file.url}
        callToActions={heroCallToActions}
        centered={centered}
      />

      {pageNode.contentSections &&
        pageNode.contentSections.map(mapContentSectionSection)}
    </Page>
  );
}

export const landingPageFragment = graphql`
  fragment LandingPageData on ContentfulLandingPageConnection {
    edges {
      node {
        contentSections {
          ... on ContentfulContentSection {
            internal {
              type
            }
            content {
              content
            }
          }
          # ... on ContentfulFeatureSection {
          #   title
          #   features {
          #     title
          #     icon {
          #       file {
          #         url
          #       }
          #     }
          #     description {
          #       description
          #     }
          #     callToAction {
          #       label
          #       link
          #     }
          #   }
          #   internal {
          #     type
          #   }
          # }
          ... on ContentfulLogoSection {
            title
            brands {
              brandName
              logo {
                file {
                  url
                }
              }
            }
            internal {
              type
            }
          }
          ... on ContentfulMediumSection {
            internal {
              type
            }
            showAreYouReadyAllReady
          }
          ... on Node {
            ... on ContentfulTestimonialSection {
              id
              image {
                file {
                  url
                }
              }
              testimonial {
                content {
                  content {
                    value
                  }
                }
              }
              author {
                author
              }
            }
          }
          ... on ContentfulColumnSection {
            id
            darkened
            imageSide
            showRequestDemoForm
            title
            content {
              content
            }
            image {
              file {
                url
              }
            }
            # phoneScreenImage {
            #   file {
            #     url
            #   }
            # }
            callToActions {
              ... on ContentfulRequestDemoCallToAction {
                id
                placeholder
                buttonLabel
                section
                internal {
                  type
                }
              }
              ... on ContentfulButtonCallToAction {
                id
                label
                link
                internal {
                  type
                }
              }
            }
          }
          ... on ContentfulSecondaryHeroSection {
            id
            content {
              content
            }
          }
          # ... on ContentfulSidekickSection {
          #   id
          #   title
          #   subtitle {
          #     subtitle
          #   }
          # }
          ... on ContentfulAudienceSection {
            id
            title
            userGroups {
              link
              name
              icon {
                file {
                  url
                }
              }
            }
          }
        }
        heroTitle
        heroSubtitle {
          heroSubtitle
        }
        heroCallToActions {
          ... on ContentfulRequestDemoCallToAction {
            id
            placeholder
            buttonLabel
            section
            internal {
              type
            }
          }
          # ... on ContentfulButtonCallToAction {
          #   id
          #   label
          #   link
          #   internal {
          #     type
          #   }
          # }
        }
        phoneScreenImage {
          file {
            url
          }
        }
        centered
        seoTitle
        seoDescription {
          seoDescription
        }
        seoImage {
          file {
            url
          }
        }
      }
    }
  }
`;

export default LandingPage;
