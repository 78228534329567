import React from "react";
import Section from "../Section/Section";
import classNames from "classnames";
import classes from "./SidekickSection.module.css";

function SidekickSection({ title, subtitle }) {
  return (
    <Section className={classes.sidekick} innerClassName={classes.inner}>
      <div
        className={classNames(classes.wrapper, {
          [classes.small]: title.length > 100,
        })}
      >
        <h1>{title}</h1>
        {subtitle && <p>{subtitle}</p>}
      </div>
    </Section>
  );
}

export default SidekickSection;
