import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import classes from "./ButtonCallToAction.module.css";

function ButtonCallToAction({ label, link, secondary }) {
  if (link.startsWith("http")) {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={classNames(
          secondary ? classes.secondaryCallToAction : classes.callToAction
        )}
      >
        {label}
      </a>
    );
  }

  return (
    <Link
      to={link}
      className={classNames(
        secondary ? classes.secondaryCallToAction : classes.callToAction
      )}
    >
      {label}
    </Link>
  );
}

export default ButtonCallToAction;
