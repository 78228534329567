import React from "react";
import classNames from "classnames";
import classes from "./Section.module.css";

function SectionColumn({ children, className }) {
  return (
    <div className={classNames(classes.column, className)}>{children}</div>
  );
}

export default SectionColumn;
