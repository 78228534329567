import React from "react";
import RequestDemoCallToAction from "../RequestDemoCallToAction/RequestDemoCallToAction";
import ButtonCallToAction from "../ButtonCallToAction/ButtonCallToAction";

export default function CallToActions({ callToActions, secondary }) {
  return callToActions.map(callToAction => {
    switch (callToAction.internal.type) {
      case "ContentfulRequestDemoCallToAction":
        return (
          <RequestDemoCallToAction
            key={callToAction.id}
            buttonLabel={callToAction.buttonLabel}
            placeholder={callToAction.placeholder}
            section={callToAction.section}
            secondary={secondary}
            intercomTags={callToAction.intercomTags}
          />
        );

      case "ContentfulButtonCallToAction":
        return (
          <ButtonCallToAction
            key={callToAction.id}
            label={callToAction.label}
            link={callToAction.link}
            secondary={secondary}
          />
        );

      default:
        throw new Error(
          `(${callToAction.id}) Invalid call to action type: ${callToAction.internal.type}`
        );
    }
  });
}
